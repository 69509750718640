import { initFontAwesome } from "@solvari/common-fe/integrations";

import "../shared/components/s-checkbox-dropdown/sCheckboxDropdown";
import "@/admin/utils/sentry";

import "../platform/components/review-widget/reviewScore.css";
import "../shared/components/pro/chosen.css";
import "../shared/components/pro/s-company-badges/sCompanyBadge.css";
import "../shared/components/pro/s-company-card/sCompanyCard.css";

initFontAwesome();
